import { useEffect, useState } from "react";
import React from "react";
import Axios from "axios";
import Blobs from "./Blobs.tsx";
//import { FaPlayCircle, FaPauseCircle, FaLoop } from "react-icons/fa";
/*
TODO:
- wlasne controlki
	-- controls={false}
	- wlasny div manipulujacy document.getElementById("player")   <--- kurwa badz pewny ze filmik jest zaladowany wpierw bo error i all zepsute inaczej

- localstorage z timestampami

- tagowanie filmow i filtrowanie po tagach
	- onContextMenu:
		- oznacz jako obejrzane/nieobejrzane
		- dodaj/usun ulubione
		- dodaj/usun tag
	- header: dostepne tagi + all,
		dostepne.filmiki.find(film => {
			var zaznaczoneTagi = [...tagi].length;
			tagi.map(tag => {
				if(tag in film.tagi) zaznaczoneTagi--;
			})
			if(zaznaczoneTagi <= 0) return true;
		}) ? wyswietl filmik : "";

- wklej link i pobierz
	- frontend input, axios && sprawdz json stan pobieranych
	- backend odbierz url, sprawdz url, exec wget && dodaj do json pobieranych
	-- frontend jesli stan pobieranego ukonczony, daj info o rozmiarze MB
		-- jesli MB duze frontend axios -> backend exec ffmpeg opt
		-- jesli MB male frontend axios -> backend exec miniaturka.sh && mv gotowe/
*/

interface videoData {
	id: number,
	nazwa: string,
	czasTrwania: number
}

interface filter {
	categories: number[],
	maxLength: number,
	minLength: number,
	name: string
}

interface category {
	id: number,
	name: string
}

interface availableData {
	categories: category[],
	videos: videoData[],
	videoCategories: videoCategories[],
	checked: boolean
}

interface videoCategories {
	video: videoData,
	category: category[]
}

export default function App() {
	const [playing, setPlaying] = useState({ video: "", loop: false });
	const [sesja, setSesja] = useState(false);
	const [filter, setFilter] = useState<filter>({categories: [], maxLength: 0, minLength: 0, name: ""});
	const [mobileSearch, setMobileSearch] = useState<boolean>(false);
	const [available, setAvailable] = useState<availableData>();

	const checkSession = async () => {
		await Axios.post("https://pop.rzak.eu:3001/session/"+localStorage.getItem("token")).then((r) => {
			if(!r.data['odp']){
				localStorage.clear();
				window.location.href = "/zaloguj";
			} else {
				setSesja(true);
			}
		}).catch((er) => {
			localStorage.clear();
			window.location.href = "/zaloguj";
		});
	}

	const formatTime = (s: number) => {
		let hh: number | string = Math.floor( s/3600 );
		let mm: number | string = Math.floor( (s%3600) / 60 );
		let ss: number | string = Math.floor( (s%3600) % 60 );

		if(ss < 10) ss = '0'+ss;
		if(hh > 0){
			if(hh < 10) hh = "0"+hh;
			if(mm < 10) mm = '0'+mm;
			return `${hh}:${mm}:${ss}`;
		} else {
			return `${mm}:${ss}`;
		}
	};

	const getVideos = async () => {
		await Axios.get("https://pop.rzak.eu:3001/test").then((res) => {
			const formattedCat: category[] = res.data['categories'].map((c: {id: number, nazwa: string}) => ({id: c.id, name: c.nazwa}));

			let formattedVideoCat: videoCategories[] = [];
			res.data["filmiki"].map((v: videoData) => {
				let tmpCat: category[] = [];
				res.data['videoCategories'].map(
					(vc: {filmikId: number, katId: number, id: number}) => {
						// jesli to ten filmik
						if(vc.filmikId === v.id){
							const znajdz = formattedCat.find(c => c.id === vc.katId);
							if(znajdz === undefined) return;
							tmpCat.push(znajdz)
						}
					}
				);
				if(tmpCat.length) formattedVideoCat.push({video: v, category: tmpCat});
			})
			console.log(formattedVideoCat);

			setAvailable({videos: res.data["filmiki"], categories: formattedCat, videoCategories: formattedVideoCat, checked: true });
			!localStorage.knownVideos && (localStorage.knownVideos = JSON.stringify(res.data["filmiki"]));
		});
	};

	useEffect( () => {
		if(!sesja) checkSession();
		if(sesja && !available?.checked) getVideos();
	}, [available, sesja])

	const returnVideos = () => {
		const newVids = available?.videos.length! - JSON.parse(localStorage.knownVideos).length;
		return (
			<>
				<div className="flex flex-wrap flex-grow justify-center gap-[1.5rem_2rem] p-3 max-lg:gap-3 max-sm:gap-2.5 overflow-y-auto h-fit max-h-full">
					{available?.videos.map((video: videoData) => {
						if(filter?.name && !video.nazwa.toLowerCase().includes(filter.name.toLowerCase())) return;
						if(filter?.maxLength && (video.czasTrwania > filter.maxLength)) return;
						if(filter?.minLength && (video.czasTrwania < filter.minLength)) return;
						const url = "https://pop.rzak.eu/filmik/" + video.nazwa + ".mp4";
						const preview = 'url("' + url + '_preview.jpg")';
						return (
							<div
								// className="video"
								className="group relative flex w-80 rounded-xl shadow-[0_0_8px_0_#0c0c0c] cursor-pointer select-none flex-col overflow-hidden transition-all duration-300 hover:scale-110 max-lg:w-60 max-sm:w-44"
								key={video.id}
								onClick={() => playVideo(url)}
								onContextMenu={(e) => e.preventDefault()}
							>
								<div
									// className="videoPreview"
									className="aspect-[213/120] w-full bg-center opacity-70 transition-opacity duration-300 group-hover:opacity-100 group-hover:animate-[podglad_5s_steps(9)_infinite_0.6s]"
									style={{
										backgroundPositionY: "100%",
										backgroundSize: "100% 1000%",
										backgroundImage: preview,
									}}
								/>

								{/* "videoTitle" */}
								<div className="relative flex w-full min-h-10 flex-grow bg-flirt-900 shadow-[0_0_25px_5px] shadow-flirt-900 items-center justify-center px-5 py-2 text-center text-[.9rem] font-normal leading-4 tracking-widest text-flirt-50 group-hover:text- backdrop-blur-sm transition-all duration-300 max-lg:min-h-9 max-lg:text-[0.9rem] max-sm:min-h-7 max-sm:text-[0.8rem]" >
									{video.nazwa}
									{/* videoDuration */}
									<div className="absolute bottom-[calc(100%+10px)] right-[10px] bg-flirt-900/70 shadow-inner shadow-flirt-900 rounded-md px-1.5 py-0.5 text-sm font-semibold italic text-flirt-50 backdrop-blur-sm max-lg:px-1 max-lg:py-[1px] max-lg:text-[0.8rem] max-sm:text-[0.7rem]" >
										{formatTime(video.czasTrwania)}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</>
		);
	};

	const playVideo = (video: string) => {
		const name = video.replace("https://pop.rzak.eu/filmik/", "").replace(".mp4", "");
		const knownVideos = JSON.parse(localStorage.knownVideos);
		if (!(name in knownVideos)) { knownVideos.push(name); localStorage.knownVideos = JSON.stringify(knownVideos); }
		setPlaying({ ...playing, video: video });
	};

	const returnFilters = () => {
		return (
			<div className={`max-sm:fixed left-0 bottom-0 right-0 max-sm:pb-24 max-sm:translate-y-full max-sm:bg-flirt-900/90 max-sm:backdrop-blur-sm ${mobileSearch && "max-sm:-translate-y-0"} z-[1] sm:max-w-96 bg-flirt-900 flex sm:h-full sm:min-w-80 flex-col gap-2 p-6 transition-transform`}>
				<p className="text-flirt-50 font-bold tracking-widest">Search</p>
				<input
					className="outline-0 px-4 py-2 font-bold text-base bg-flirt-50 text-flirt-700 border-0"
					type="text"
					value={filter?.name ? filter.name : ""}
					onChange={(e) =>
						setFilter({ ...filter!, name: e.target.value })
					}
				/>
				<p className="text-flirt-50 font-bold tracking-widest">Minimum length (sec)</p>
				<input
					className="outline-0 px-4 py-2 font-bold text-base bg-flirt-50 text-flirt-700 border-0"
					type="number"
					onChange={(e) =>
						setFilter({
							...filter!,
							minLength: Number(e.target.value),
						})
					}
				/>
				<p className="text-flirt-50 font-bold tracking-widest">Maximum length (sec)</p>
				<input
					className="outline-0 px-4 py-2 font-bold text-base bg-flirt-50 text-flirt-700 border-0"
					type="number"
					onChange={(e) =>
						setFilter({
							...filter!,
							maxLength: Number(e.target.value),
						})
					}
				/>
				<p className="text-flirt-50 font-bold tracking-widest mt-3">Categories {filter?.categories.length ? `(${filter.categories.length} selected)` : ""}</p>
				<div className="flex flex-wrap gap-2">
					{available?.categories.map((c) => {
						const isSelected = filter?.categories.includes(c.id);
						return (
							<div
								className={`flex flex-grow cursor-pointer justify-center font-bold tracking-wider ${isSelected ? "bg-flirt-600 text-flirt-50" : "bg-flirt-50 text-flirt-700"} p-3 transition-colors`}
								key={`${c.name + c.id}`}
								onClick={() => {
									isSelected
										? setFilter({
												...filter!,
												categories:
													filter!.categories.filter(
														(v) => v != c.id,
													),
											})
										: setFilter({
												...filter!,
												categories: [
													...filter!.categories,
													c.id,
												],
											});
								}}
							>
								{c.name}
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	return (
		<>
			<div className="flex justify-between w-full h-full">
				{ available?.categories.length && returnFilters() }
				{ (available?.videos ? returnVideos() : <span>No available videos!</span>)}
			</div>
			<div className="fixed z-[2] bottom-5 right-8 max-sm:flex sm:hidden justify-center items-center w-12 h-12 rounded-full bg-flirt-700" onClick={() => setMobileSearch(!mobileSearch)}>SET</div>
			{playing.video && (
				<div className="layerVideo">
					<div className="layerOptions">
						<div className="layerExit" onClick={() => setPlaying({ ...playing, video: "" })}>EXIT</div>
						<div className={playing.loop ? "layerLoop active" : "layerLoop"} onClick={() => setPlaying({ ...playing, loop: !playing.loop })}>LOOP</div>
					</div>
					<video
						id="player"
						controls
						src={playing.video}
						loop={playing.loop}
						onContextMenu={(e) => e.preventDefault()}
					/>
				</div>
			)}
		</>
	);
}
